/*
  Super Spelling Bee - A vocabulary game with integrated hints
  Copyright (C) 2023 Austin Miller

  This program is free software: you can redistribute it and/or modify it under
  the terms of the GNU General Public License as published by the Free Software
  Foundation, either version 3 of the License, or (at your option) any later
  version.

  See the LICENSE file or https://www.gnu.org/licenses/ for more details.
*/

import { useAppSelector } from "@/app/hooks";
import { selectUser } from "@/features/auth";
import { UserMenu } from "@/features/auth/components/headerAuth/UserMenu";
import { MobileAuthLinks } from "@/features/auth/components/MobileAuthLinks";

export function MobileAuth() {
  const user = useAppSelector(selectUser);
  if (user) return <UserMenu isMobile />;
  return <MobileAuthLinks />;
}
